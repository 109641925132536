@import '~bootstrap/scss/bootstrap';
@import 'colors';
@import 'navbar/navbar';

body {
    background: $bg;
}

.table > tbody {
    width: 100%;
}

.table th {
    width: 15em;
}

.table td {
    vertical-align: middle;
}

a {
    // Bootstrap 5 automatically underline links
    // Temporary solution until we get rid of BS
    // https://getbootstrap.com/docs/5.0/migration/#content-reboot-etc
    text-decoration: none;
}
