@import 'navbar-utils';

.sf-navbar .dropdown-wrapper {
    position: relative;
    height: 100%;

    button {
        display: flex;
        align-items: center;
        height: 100%;
        border: none;
        padding: 0 20px;
        background: none;
        color: black;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        .dropdown-user-icon {
            margin-right: 10px;
        }

        .dropdown-chevron-icon {
            font-size: 10px;
            margin-left: 10px;
            width: 20px;
        }
    }

    .dropdown-collapse {
        display: none;
        position: absolute;
        top: calc(100% - 12px);
        right: 20%;
        background: white;
        border-radius: 4px;
        min-width: 100%;

        ul {
            display: block;
            padding-inline-start: 0;
            margin: 0;

            li {
                display: block;
                white-space: nowrap;
                border-bottom: 1px solid #e3e3e3;

                &:last-of-type {
                    border-bottom: none;
                }

                &:first-of-type a {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }

                &:last-of-type a {
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                }

                a {
                    color: black;
                    display: block;
                    padding: 10px 20px;
                    position: relative;

                    &:hover {
                        background: #f6f6f6;
                        text-decoration: none;
                    }

                    .link-text {
                        position: relative;
                    }

                    &:hover .link-text,
                    &:focus .link-text {
                        visibility: hidden;
                    }

                    &:hover .link-text::before,
                    &:focus .link-text::before {
                        @include bold-link-before;
                        content: attr(data-title);
                    }

                    span {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    &:focus-within .dropdown-collapse,
    &:hover .dropdown-collapse {
        display: block;
    }

    &:focus-within .dropdown-chevron-icon,
    &:hover .dropdown-chevron-icon {
        transform: rotate(180deg);
        margin: 0 0 0 10px;
    }
}
