@import '../colors';
@import 'navbar-utils';

.sf-navbar .menu {
    display: flex;
    height: 100%;

    .mobile-menu-switch {
        display: none;
        border: none;
        background: none;
        border-radius: 5px;
        width: 2em;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    .menu-list {
        display: flex;
        height: 100%;
        margin: 0;
        padding-inline-start: 0;

        li {
            display: block;
            height: 100%;

            a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                padding: 0 20px;
                color: black;
                text-decoration: none;
                position: relative;
            }

            &.active a,
            &:hover a,
            a:focus {
                visibility: hidden;
            }

            &.active a::before,
            &:hover a::before,
            a:focus::before {
                @include bold-link-before;
                border-bottom: 3px solid #017994;
            }
        }
    }
}

@media (max-width: $navbar-breakpoint) {
    .sf-navbar .menu {
        .mobile-menu-switch,
        .menu-list.active {
            display: block;
        }

        .menu-list {
            display: none;
            position: fixed;
            background: $navbar-bg;
            top: $navbar-height;
            left: 0;
            width: 100vw;
            height: auto;

            li {
                height: auto;
                text-align: center;

                a {
                    padding: 20px 0;
                    width: 100%;
                }

                &.active a::before,
                &:hover a::before,
                a:focus::before {
                    border-bottom: none;
                }
            }
        }
    }
}
