@import '../colors';
@import 'navbar-utils';
@import 'navbar-menu';
@import 'navbar-dropdown';

.sf-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: $navbar-height;
    background: $navbar-bg;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 10;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);

    .brand {
        img {
            height: calc(#{$navbar-height} - 0.5rem);
        }
    }

    .flex-wrapper {
        display: flex;
        align-items: center;
        height: 100%;

        @media (max-width: $navbar-breakpoint) {
            flex-direction: row-reverse;
        }
    }
}
