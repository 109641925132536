$text: #3a3a3a;
$error: #f82849;

$bg: #f6f8fc;
$bg-dark: #eeeeee;
$bg-darker: #cccccc;
$bg-error: mix($error, $bg, 20);

$primary: #25b2cc;
$primary-light: #41c5dc;
$primary-dark: #23a6be;
$primary-darker: #2097ac;

$secondary: #ffc929;
$secondary-light: #ffd65c;
$secondary-dark: #f5b800;

$navbar-bg: #ffffff;

:root {
    --site-bg: #{$bg};
}
