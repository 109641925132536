@mixin bold-link-before {
    display: flex;
    align-items: center;
    justify-content: center;
    content: attr(title);
    font-weight: bold;
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none;
    box-sizing: border-box;
    transition: 0.2s all;
}

@mixin navbar-btn-focus-style {
    background: rgba(0, 0, 0, 0.2);
    outline: none;
}

$navbar-height: 60px;

$navbar-breakpoint: 1000px;

:root {
    --navbar-height: #{$navbar-height};
}
